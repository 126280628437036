import "./App.css";
import { Map, TileLayer, Marker, Popup, GeoJSON } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import React, { useState, useEffect } from "react";
import MapMarker from "./planeIcon.svg";
import AirportIcon from "./airport.svg";
import { Icon } from "leaflet";
import { default as bezierSpline } from "@turf/bezier-spline";
import * as helpers from "@turf/helpers";

import axios from "axios";

import RotatedMarker from "./RotatedMarker";
import PopUpFlightInfo from "./PopUpFlightInfo";

const MapMarkerIcon = new Icon({
  iconUrl: MapMarker,
  iconSize: [40, 40],
});

const MapMarkerAirportIcon = new Icon({
  iconUrl: AirportIcon,
  iconSize: [40, 40],
});

function App() {
  const [location, setLocation] = useState([]);

  const [loading, setLoading] = useState(true);

  const [flightInfo, setFlightInfo] = useState(false);

  const [departure, setDeparture] = useState();

  const [arrival, setArrival] = useState();

  const [flightPath, setFlightPath] = useState(false);

  let params = new URL(document.URL).searchParams;
  const flightId = params.get("flight_id");

  const getInfo = () => {
    axios
      .get(
        `https://virtuallh.com/vam/get_flight_info.php?flight_id=${flightId}`
      )
      .then(function (response1) {
        setFlightInfo(response1.data[0]);
        axios
          .get(
            `https://virtuallh.com/vam/get_flight_path.php?flight_id=${flightId}`
          )
          .then(function (response) {
            const ArrayHandler = [];

            response.data.map((path) => {
              ArrayHandler.push([path.latitude, path.longitude]);
            });
            setFlightPath(ArrayHandler);
            console.log(ArrayHandler);

            setTimeout(() => {
              axios
                .get(
                  `https://virtuallh.com/vam/get_current_location.php?ident=${response1.data[0].departure}`
                )
                .then(function (response) {
                  const location = [
                    response.data[0].latitude_deg,
                    response.data[0].longitude_deg,
                  ];
                  setDeparture(location);

                  axios
                    .get(
                      `https://virtuallh.com/vam/get_current_location.php?ident=${response1.data[0].arrival}`
                    )
                    .then(function (response) {
                      const location = [
                        response.data[0].latitude_deg,
                        response.data[0].longitude_deg,
                      ];
                      setArrival(location);
                      setLoading(false);
                    });
                });
            }, 20);
          });
      });
  };

  useEffect(() => {
    getInfo();
  }, []);

  const getCurve = (positions) => {
    const line = helpers.lineString(
      positions.map((latLng) => [parseFloat(latLng[1]), parseFloat(latLng[0])])
    );

    const curved = bezierSpline(line);

    return curved;
  };

  return loading ? (
    "Loading..."
  ) : (
    <Map
      center={departure}
      zoom={3}
      scrollWheelZoom={true}
      style={{ width: "100%", height: "100%", position: "absolute" }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions"target="_blank">CARTO</a>'
        url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
      />

      <Marker position={departure} icon={MapMarkerAirportIcon}>
        <Popup>Departure airport</Popup>
      </Marker>

      <GeoJSON data={getCurve(flightPath)} />

      <Marker position={arrival} icon={MapMarkerAirportIcon}>
        <Popup>Arrival airport</Popup>
      </Marker>
    </Map>
  );
}

export default App;
